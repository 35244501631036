import React, { useState } from 'react';
import { FC } from 'react';
import { useGet } from '../../hooks/useApi';
import { tenantApi } from '../../APIs/tenants';
import { reformat } from '../../utils/DateUtils';
import { comma } from '../../utils/FormUtils';
import { SpinnerIcon } from '../atomic/icons';
import { TaskSummaryModel } from '../../models/TaskModel';
import { useAuth } from '../../contexts/AuthContext';

type Prop = { tasks: TaskSummaryModel[]; month: string };
export const TenantStorageStatus: FC<Prop> = ({ tasks, month }) => {
    const { data: status } = useGet(tenantApi.get.getTenantStatus);

    const { isSuperAdmin } = useAuth();

    if (!status) {
        return <></>;
    }
    const count =
        tasks.length &&
        tasks.reduce(function (total: number, item: TaskSummaryModel) {
            return total + item.image;
        }, 0);
    return (
        <>
            <ul className="tenant-status-list">
                {!isSuperAdmin() && (
                    <li>
                        <b>当月推論枚数:</b>
                        {comma(status.data.aiRequestCount)}/
                        {comma(status.data.maxAiRequest)}枚
                    </li>
                )}
                {month.length > 0 && (
                    <li>
                        <b>{month}推論画像数:</b>
                        {comma(count)}枚
                    </li>
                )}
                <li>
                    <b>ストレージ使用量:</b> {comma(status?.data.size)} GB
                </li>
                <li>
                    <b>最大サイズ:</b>{' '}
                    {comma(status?.data.availableStorageSize)} GB
                </li>
                <li>
                    <b>使用率:</b> {status?.data.usage}%
                </li>
                <li>
                    <b>取得日:</b> {reformat(status.data.finishedAt)}
                </li>
            </ul>
        </>
    );
};
