import { FC, useReducer } from 'react';
import { DialogBox } from '../../atomic/dialog';
import { NumberField, TextField } from '../../atomic/forms';
import React from 'react';
import { SlideSwitch } from '../../atomic/switches';
import {
    CreateTenantRequest,
    TenantModel,
    UpdateTenantRequest,
} from '../../../models/TenantModel';
import { useAuth } from '../../../contexts/AuthContext';
import { validateEmail } from '../../user/lib/validate';
import { inviteUser } from '../../../APIs/userApi';
import {
    createTenantForSuperAdmin,
    tenantApi,
    updateTenantForAdmin,
} from '../../../APIs/tenants';
import { mutateAsync } from '../../../hooks/useApi';

type Prop = {
    isOpen: boolean;
    setIsOpen: any;
    tenant?: TenantModel;
};

export const TenantFormDialog: FC<Prop> = (props) => {
    const { setIsOpen, tenant } = props;
    const isCreate = tenant?.tenantId == undefined;
    const { isSuperAdmin } = useAuth();
    const ref = React.useRef<DialogBox>();

    const initialState: CreateTenantRequest | UpdateTenantRequest = isCreate
        ? ({
              tenantId: tenant?.tenantId,
              email: '',
              name: '',
              availableStorageSize: 100,
              maxUserLicenses: 10,
              upload: true,
              permission: false,
              aiRequest: true,
          } as CreateTenantRequest)
        : (tenant as UpdateTenantRequest);

    const reducer = (
        state: any,
        action: { field: string; payload: string | number | boolean }
    ) => {
        const newState = {
            ...state,
            [action.field]: action.payload,
        };
        return newState;
    };

    const [formState, dispatch] = useReducer(reducer, initialState);

    function createAdminUser(email: string, tenantId: string) {
        inviteUser({
            email: email,
            role: 'Admin',
            tenantId: tenantId,
        })
            .then((resp) => {
                if (resp.success) {
                    setIsOpen(false);
                } else {
                    ref?.current?.addError(resp, 'ユーザの招待に失敗しました');
                }
            })
            .catch((err) => {
                console.warn(err);
                ref?.current?.addError(err, 'ユーザの招待に失敗しました');
            })
            .finally(() => {});
    }

    const createTenant = async () => {
        const createTenant: CreateTenantRequest = { ...formState };
        const resp = await mutateAsync(
            tenantApi.mutate.createTenantForSuperAdmin,
            createTenant
        );

        if (
            resp.status === 200 &&
            resp.data &&
            resp.data.status === 'success'
        ) {
            createAdminUser(formState.email, resp.data.data.tenantId);
        } else {
            ref?.current?.addError('エラーが発生しました');
        }
        return true;
    };
    const updateTenant = async () => {
        const updateTenant: UpdateTenantRequest = { ...formState };
        const resp = await mutateAsync(
            tenantApi.mutate.updateTenantForAdmin,
            updateTenant
        );

        if (
            resp.status === 200 &&
            resp.data &&
            resp.data.status === 'success'
        ) {
            setIsOpen(false);
        } else {
            ref?.current?.addError('エラーが発生しました');
        }

        return true;
    };

    const onCancel = () => {
        return setIsOpen(false);
    };
    const onOK = React.useCallback(() => {
        if (isCreate) {
            if (validateEmail(formState.email) === false) {
                ref?.current?.addError(
                    'メールアドレスのフォーマットが不正です'
                );
                return false;
            }
        }
        if (formState.maxUserLicenses < 1) {
            ref?.current?.addError('ユーザー数は１以上である必要があります');
            return false;
        }
        if (formState.availableStorageSize < 1) {
            ref?.current?.addError(
                'ストレージ容量は１以上である必要があります'
            );
            return false;
        }

        console.log(formState);

        if (formState.tenantId) {
            return updateTenant();
        }
        return createTenant();
    }, [formState]);
    return (
        <DialogBox
            title={isCreate ? 'テナント作成' : 'テナント更新'}
            onOK={onOK}
            onCancel={onCancel}
            ref={ref}
        >
            <ul className="fields">
                <li>
                    <TextField
                        label="テナント名"
                        value={formState.name}
                        required={true}
                        onChange={(e) =>
                            dispatch({
                                field: 'name',
                                payload: e.currentTarget.value,
                            })
                        }
                    />
                    {isCreate && (
                        <TextField
                            label="メールアドレス"
                            value={formState.email}
                            required={true}
                            errorMessage={'エラーです'}
                            onChange={(e) =>
                                dispatch({
                                    field: 'email',
                                    payload: e.currentTarget.value,
                                })
                            }
                        />
                    )}
                    <NumberField
                        label="最大ストレージサイズ(GB)"
                        min={1}
                        value={String(formState.availableStorageSize)}
                        required={true}
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'availableStorageSize',
                                payload: e.currentTarget.value,
                            })
                        }
                    />
                    <NumberField
                        label="最大ユーザー数"
                        min={1}
                        value={String(formState.maxUserLicenses)}
                        required={true}
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'maxUserLicenses',
                                payload: e.currentTarget.value,
                            })
                        }
                    />
                    <NumberField
                        label="AI実行許可枚数"
                        min={1}
                        value={String(formState.maxAiRequest)}
                        required={true}
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'maxAiRequest',
                                payload: e.currentTarget.value,
                            })
                        }
                    />
                    <SlideSwitch
                        defaultValue={formState.aiRequest}
                        name={'AI実行を許可する'}
                        text="AI実行を許可する"
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'aiRequest',
                                payload: !formState.aiRequest,
                            })
                        }
                    />
                    <SlideSwitch
                        defaultValue={formState.upload}
                        name={'アップロードを許可する'}
                        text="アップロードを許可する"
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'upload',
                                payload: !formState.upload,
                            })
                        }
                    />
                    <hr />
                    <SlideSwitch
                        defaultValue={formState.permission}
                        name={'ユーザグループ'}
                        text="ユーザグループ"
                        disabled={!isSuperAdmin()}
                        onChange={(e) =>
                            dispatch({
                                field: 'permission',
                                payload: !formState.permission,
                            })
                        }
                    />
                </li>
            </ul>
        </DialogBox>
    );
};
