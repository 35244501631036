import React, { FC, useState } from 'react';
import { SelectField, SelectFieldOption, TextField } from '../atomic/forms';
import { Button } from '../atomic/buttons';
import { DialogBox } from '../atomic/dialog';
import {
    GroupModel,
    GroupUserRequest,
    GroupsModel,
    UserRole,
    inviteUser,
    userApi,
} from '../../APIs/userApi';
import { Loading } from '../atomic/effects';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { mutateAsync, useGet } from '../../hooks/useApi';
import { tenantApi } from '../../APIs/tenants';
import { CheckBox } from '../atomic/switches';
import { mutate } from 'swr';

type Prop = {};

export const UserInviteModal: FC<Prop> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<UserRole>('User');
    const [loading, setLoading] = useState<boolean>(false);
    const ref = React.useRef<DialogBox>();
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());
    const { data: tenantStatus } = useGet(tenantApi.get.getTenantStatus);
    const userApiGroups = useGet(userApi.get.groups());
    const [groups, setGroups] = useState<GroupsModel>([]);
    const [selectGroups, setSelectGroups] = useState<Array<string>>([]);

    const userList = useGet(userApi.get.users());

    const openModal = () => {
        resetSelectedGroups();
        setEmail('');
        setRole('User');
        setIsOpen(true);
    };

    const roles = [
        {
            text: '管理者',
            value: 'Admin',
        },
        {
            text: 'ユーザ',
            value: 'User',
        },
        {
            text: 'ゲスト',
            value: 'External',
        },
    ];

    React.useEffect(() => {
        if (userApiGroups && userApiGroups.data && userApiGroups.data.data) {
            setGroups(userApiGroups.data.data);
        }
    }, [userApiGroups]);

    const onOK = React.useCallback(async () => {
        if (!userInfo?.tenantId) {
            ref?.current?.addError('テナントIDの取得に失敗しました');
            return false;
        }
        setLoading(true);
        inviteUser({
            email: email,
            role: role,
            tenantId: userInfo?.tenantId,
        })
            .then((resp) => {
                if (resp.success) {
                    if (role == 'User') {
                        const createGroupUser = async (userId, groupId) => {
                            const request: GroupUserRequest = {
                                userId: userId,
                                groupId: groupId,
                            };
                            const resp = await mutateAsync(
                                userApi.mutate.createGroupUser(),
                                request
                            )
                                .catch((err) => {
                                    console.warn(err);
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        };
                        for (const groupId of selectGroups) {
                            createGroupUser(resp.data.userId, groupId);
                        }
                    }
                    userList.mutate();
                    setIsOpen(false);
                    setEmail('');
                    setRole('User');
                } else {
                    ref?.current?.addError(resp, 'ユーザの招待に失敗しました');
                }
            })
            .catch((err) => {
                console.warn(err);
                ref?.current?.addError(err, 'ユーザの招待に失敗しました');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [email, role, userInfo?.tenantId, selectGroups]);
    const onCancel = React.useCallback(() => {
        setIsOpen(false);
        resetSelectedGroups();
    }, []);

    const onChangeRole = React.useCallback(
        (val) => {
            setRole(val);
        },
        [role]
    );

    const isChecked = (group) => {
        return selectGroups.some((_group) => _group == group.groupId);
    };
    const onChange = (group: GroupModel) => {
        if (selectGroups.some((_group) => _group == group.groupId)) {
            const _group = selectGroups.filter(
                (_group) => _group !== group.groupId
            );
            setSelectGroups(_group);
        } else {
            const _group = [...selectGroups, group.groupId];
            setSelectGroups(_group);
        }
    };

    const resetSelectedGroups = () => {
        setSelectGroups([]);
    };

    if (!tenantStatus) {
        return <Loading />;
    }

    return (
        <>
            <div>
                <Button className="primary" onClick={openModal}>
                    メンバー招待
                </Button>
                {isOpen && (
                    <DialogBox
                        title="メンバ招待"
                        onOK={onOK}
                        onCancel={onCancel}
                        ref={ref}
                        disabled={
                            tenantStatus.data.permission
                                ? role === 'User' && selectGroups.length == 0
                                : false
                        }
                    >
                        <ul className="fields">
                            <li>
                                <TextField
                                    label="メールアドレス"
                                    _onChange={setEmail}
                                    value={email}
                                    required={true}
                                />
                            </li>
                            <li>
                                <SelectField
                                    label="Role"
                                    _onChange={onChangeRole}
                                    value={role}
                                    required={true}
                                    options={roles}
                                />
                            </li>
                            {role === 'User' &&
                                tenantStatus.data.permission && (
                                    <>
                                        <label>グループ</label>
                                        <li>
                                            <ul className="groups">
                                                {groups.map((group) => {
                                                    return (
                                                        <li
                                                            key={group.groupId}
                                                            className=""
                                                        >
                                                            <CheckBox
                                                                label={
                                                                    group.name
                                                                }
                                                                name="aspect-enabled"
                                                                checked={isChecked(
                                                                    group
                                                                )}
                                                                _onChange={() =>
                                                                    onChange(
                                                                        group
                                                                    )
                                                                }
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </li>
                                    </>
                                )}
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
