import React from 'react';
import { DialogBox, DialogErrorObject } from '../atomic/dialog';
import { TenantTagModel, TagTypeModel, TagType } from '../../models/TagModel';
import {
    TextField,
    NumberField,
    SelectField,
    DateField,
    LocationField,
} from '../atomic/forms';
import { ulid } from 'ulidx';
import { tagDescendants } from '../../APIs/tagApi';
import { Icon, Icons } from '../atomic/icons';
import { Link } from 'react-router-dom';
import { Loading } from '../atomic/effects';

export interface FolderDetailTagTypeModel {
    tagTypeId: string;
    tagTypeName: string;
    tagDetailId: string;
    tagType: number;
    sortOrder: number;
    list: Array<string> | null;
    value: string;
}

export interface FolderDetailTagModel {
    tenantTagId: string;
    tagId: string;
    tagName: string;
    isOverWrite: boolean;
    values: Array<FolderDetailTagTypeModel>;
}

export interface EditTagDialogProp {
    tag: FolderDetailTagModel | null;
    onOK: (
        dialog: DialogBox,
        values: Array<FolderDetailTagTypeModel>,
        tagId: string,
        tenantTagId: string
    ) => void;
    onCancel: () => void;
    folderId: string;
    selectableTags?: Array<TenantTagModel>;
    tagTypes: Array<TagTypeModel>;
}

export const EditTagDialog = ({
    tag,
    onOK,
    folderId,
    onCancel,
    selectableTags,
    tagTypes,
}: EditTagDialogProp) => {
    const [values, setValues] = React.useState<Array<FolderDetailTagTypeModel>>(
        []
    );
    const [tenantTagId, setTenantTagId] = React.useState<string>('');
    const [disabled, setDisabled] = React.useState<boolean>(true);
    const [dialogErrorMessage, setDialogErrorMessage] = React.useState<
        DialogErrorObject[]
    >([]);
    const [sameTagChildId, setSameTagChildId] = React.useState<string | null>(
        () => (tag ? '' : null)
    );

    React.useEffect(() => {
        setValues(
            tagTypes
                .filter((item) => item.tenantTagId == tenantTagId)
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item: TagTypeModel, index: number) => {
                    const tagDetail = tag
                        ? tag.values.find(
                              ({ tagTypeId }) => tagTypeId == item.tagTypeId
                          )
                        : null;
                    const { tagDetailId, value } = tagDetail
                        ? tagDetail
                        : {
                              tagDetailId: '',
                              value:
                                  item.type == TagType.List
                                      ? (item.list || [''])[0]
                                      : '',
                          };
                    return {
                        tagTypeId: item.tagTypeId,
                        tagTypeName: item.name,
                        tagType: item.type,
                        sortOrder: item.sortOrder,
                        list: item.list,
                        tagDetailId,
                        value,
                    };
                })
        );
        if (tag) {
            setTenantTagId(tag.tenantTagId);
        } else if (selectableTags && !tenantTagId) {
            setTenantTagId(selectableTags[0].tenantTagId);
        }
    }, [tag, tenantTagId, tagTypes, selectableTags]);

    const onItemChange = React.useCallback(
        (item: FolderDetailTagTypeModel, value: string) => {
            setValues((prevState) => {
                const nextState = JSON.parse(JSON.stringify(prevState));
                const target = nextState.find(
                    ({ tagTypeId }) => tagTypeId == item.tagTypeId
                );
                if (target) {
                    target.value = value;
                }
                return nextState;
            });
        },
        []
    );

    React.useEffect(() => {
        if (!tenantTagId) {
            return;
        }
        if (!selectableTags) {
            setDisabled(false);
            return;
        }
        setDisabled(true);
        setDialogErrorMessage([]);
        tagDescendants(folderId, tenantTagId)
            .then((resp) => {
                console.log(resp.data);
                if (resp?.data?.length) {
                    const tags = resp.data;
                    setSameTagChildId(tags[0].folderId ?? '');
                } else {
                    setSameTagChildId('');
                    setDisabled(false);
                }
            })
            .catch((err) => {
                console.warn(err);
                setDialogErrorMessage([
                    {
                        message:
                            err.message || 'フォルダ情報の更新に失敗しました',
                    },
                ]);
            })
            .finally(() => {});
    }, [tenantTagId]);

    const onChangeTenantTag = React.useCallback((tenantTagId: string) => {
        setSameTagChildId(null);
        setTenantTagId(tenantTagId);
    }, []);

    return (
        <DialogBox
            errors={dialogErrorMessage}
            title="属性情報の編集"
            okText="更新"
            disabled={disabled}
            onOK={(dialog: DialogBox) =>
                onOK(dialog, values, tag ? tag.tagId : '', tenantTagId)
            }
            onCancel={onCancel}
            className="edit-tag-dialog"
        >
            {sameTagChildId == null && <Loading />}
            <table className="edit-tag-table">
                <tbody>
                    {!tag && selectableTags && (
                        <tr>
                            <th>属性種別</th>
                            <td>
                                <SelectField
                                    options={selectableTags.map((item) => {
                                        return {
                                            value: item.tenantTagId,
                                            text: item.name,
                                        };
                                    })}
                                    value={tenantTagId}
                                    _onChange={(value) =>
                                        onChangeTenantTag(value)
                                    }
                                />
                                {sameTagChildId !== null &&
                                    sameTagChildId !== '' && (
                                        <div className="warning">
                                            <Icon icon={Icons.Warning} />
                                            <Link
                                                to={`/app/folderlist/${sameTagChildId}`}
                                                onClick={onCancel}
                                            >
                                                子孫フォルダ
                                            </Link>
                                            で使用中の属性は追加できません
                                        </div>
                                    )}
                            </td>
                        </tr>
                    )}
                    {values.map(
                        (item: FolderDetailTagTypeModel, index: number) => (
                            <tr key={index}>
                                <th>{item.tagTypeName}</th>
                                <td>
                                    {item.tagType == TagType.Number ? (
                                        <NumberField
                                            _onChange={(value) =>
                                                onItemChange(item, value)
                                            }
                                            value={item.value ?? ''}
                                            disabled={sameTagChildId != ''}
                                        />
                                    ) : item.tagType == TagType.Text ? (
                                        <TextField
                                            _onChange={(value) =>
                                                onItemChange(item, value)
                                            }
                                            value={item.value ?? ''}
                                            disabled={sameTagChildId != ''}
                                        />
                                    ) : item.tagType == TagType.List ? (
                                        <SelectField
                                            _onChange={(value) =>
                                                onItemChange(item, value)
                                            }
                                            value={item.value ?? undefined}
                                            options={
                                                item.list?.map(
                                                    (text: string) => {
                                                        return {
                                                            text: text,
                                                        };
                                                    }
                                                ) || []
                                            }
                                            disabled={sameTagChildId != ''}
                                        />
                                    ) : item.tagType == TagType.Date ? (
                                        <DateField
                                            _onChange={(value) =>
                                                onItemChange(item, value)
                                            }
                                            value={item.value ?? ''}
                                            disabled={sameTagChildId != ''}
                                        />
                                    ) : item.tagType == TagType.Location ? (
                                        <LocationField
                                            _onChange={(value) =>
                                                onItemChange(item, value)
                                            }
                                            value={item.value ?? ''}
                                            folderId={folderId}
                                            disabled={sameTagChildId != ''}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </DialogBox>
    );
};
