import React from 'react';
import {
    getTemplate,
    getAiAnalysisTypes,
    updateTemplate,
    updateLabels,
    createLabels,
    deleteLabels,
    deleteTemplate,
} from '../../APIs/templateApi';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Loading } from '../atomic/effects';
import { AdminDetailPageHeader } from './AdminUtils';
import { IconButton } from '../atomic/buttons';
import { FolderOpenIcon, FolderClosedIcon } from '../atomic/icons';
import { DialogBox } from '../atomic/dialog';
import { TextField, SelectField } from '../atomic/forms';
import { ErrorContext } from '../../contexts';
import { ErrorDisplay } from '../Errors';
import { AiAnalysisType } from '../../models/AiAnalysisType';
import { Label } from '../../models/AiTemplateModel';
import { AxiosError } from 'axios';
import { ulid } from 'ulidx';

type EditLabelDialogData = {
    aiTemplateLabelId: string;
    name: string;
};

type AddLabelDialogData = {
    parentAiTemplateLabelId?: string;
    name: string;
};

type AiDialogData = {
    aiAnalysisTypeId: string;
};

type TableRowData = {
    depth: number;
    rootId: string;
    data: Label;
    index: number;
    opened: boolean;
    hasChildren: boolean;
};

type DragData = {
    item: TableRowData;
    rootId: string;
    index: number;
};

type DeleteLabelDialogData = {
    aiTemplateLabelId: string;
    name: string;
};
export const AdminTemplate = () => {
    const { aiTemplateId } = useParams() as { aiTemplateId: string };

    const [templateName, setTemplateName] = React.useState<string>('');
    const [labelList, setLabelList] = React.useState<Array<TableRowData>>([]);
    const [aiAnalysisTypeId, setAiAnalysisTypeId] = React.useState<string>('');
    const [editLabelDialogData, setEditLabelDialogData] =
        React.useState<EditLabelDialogData | null>(null);
    const [addLabelDialogData, setAddLabelDialogData] =
        React.useState<AddLabelDialogData | null>(null);
    const [aiAnalysisTypes, setAiAnarysisTypes] = React.useState<
        Array<AiAnalysisType>
    >([]);
    const [dragData, setDragData] = React.useState<DragData | null>(null);
    const [aiDialogData, setAiDialogData] = React.useState<AiDialogData | null>(
        null
    );

    const [loading, setLoading] = React.useState<boolean>(false);
    const [snapShot, setSnapShot] = React.useState(Array<Label>);
    const { setErrors } = React.useContext(ErrorContext);
    const [error, setError] = React.useState<AxiosError | null>(null);
    const [deleteLabelDialogData, setDeleteLabelDialogData] =
        React.useState<DeleteLabelDialogData | null>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        getAiAnalysisTypes()
            .then((resp) => {
                console.log(resp);
                //if (resp.success) {
                setAiAnarysisTypes(resp.data);
                //}
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
        getTemplate(aiTemplateId)
            .then((resp) => {
                console.log(resp);
                //if (resp.success) {
                setLabelList(sortTableRows(resp.data.labels));
                setAiAnalysisTypeId(resp.data.aiAnalysisTypeId);
                setTemplateName(resp.data.name);
                //}
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
    }, []);
    // ラベルの内容を表示用にソートする
    const sortTableRows = React.useCallback(
        (items: Array<Label>) => {
            const retVal: Array<TableRowData> = [];

            // 現在の表示状態を保存する
            const openedStatus = {};
            labelList.forEach((item) => {
                openedStatus[item.data.aiTemplateLabelId] = item.opened;
            });

            const eachChildren = (
                id: string,
                depth: number,
                rootId: string
            ) => {
                const siblings = items
                    .filter((item) => item.parentAiTemplateLabelId == id)
                    .sort((a, b) => a.order - b.order);

                siblings.forEach((item, index) => {
                    const insertIndex = retVal.length;
                    retVal.push({
                        depth: depth,
                        data: item,
                        rootId: rootId,
                        index: index,
                        opened: true,
                        hasChildren: true,
                    });
                    if (
                        !eachChildren(item.aiTemplateLabelId, depth + 1, rootId)
                    ) {
                        retVal[insertIndex].hasChildren = false;
                    }
                });
                return siblings.length;
            };

            items
                .filter((item) => !item.parentAiTemplateLabelId)
                .forEach((item, index) => {
                    retVal.push({
                        depth: 0,
                        data: item,
                        rootId: item.aiTemplateLabelId,
                        index: index,
                        opened: true,
                        hasChildren: true,
                    });
                    eachChildren(
                        item.aiTemplateLabelId,
                        1,
                        item.aiTemplateLabelId
                    );
                });

            retVal.forEach((item) => {
                item.opened =
                    item.data.aiTemplateLabelId in openedStatus
                        ? openedStatus[item.data.aiTemplateLabelId]
                        : true;
            });
            return retVal;
        },
        [labelList]
    );

    const onDeleteTemplate = React.useCallback(
        (dialog: DialogBox, closeDialog: () => void) => {
            setLoading(true);
            deleteTemplate(aiTemplateId)
                .then((resp) => {
                    console.log(resp);
                    navigate('/app/admin/templates');
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'テンプレートの削除に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [templateName]
    );

    const onEditTemplateName = React.useCallback(
        (newName: string, closeDialog: () => void, dialog: DialogBox) => {
            setLoading(true);
            updateTemplate(aiTemplateId, {
                aiTemplateId: aiTemplateId,
                name: newName,
            })
                .then((resp) => {
                    console.log(resp);
                    setTemplateName(newName);
                    closeDialog();
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'テンプレート名の変更に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [templateName]
    );

    const onAddNewLabel = React.useCallback((aiTemplateLabelId?: string) => {
        setAddLabelDialogData({
            parentAiTemplateLabelId: aiTemplateLabelId,
            name: '',
        });
    }, []);

    const onChangeAddLabelDialogText = React.useCallback((value: string) => {
        setAddLabelDialogData((prevState) => {
            return { ...prevState, name: value };
        });
    }, []);

    const onAddLabelDialogCancel = React.useCallback(() => {
        setAddLabelDialogData(null);
    }, []);

    const onAddLabelDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            const newName =
                addLabelDialogData?.name?.replace(/^[\s　]+|[\s　]+$/g, '') ||
                '';
            const parentAiTemplateLabelId =
                addLabelDialogData?.parentAiTemplateLabelId;
            let order = 0;
            labelList
                .filter(
                    ({ data }) =>
                        data.parentAiTemplateLabelId == parentAiTemplateLabelId
                )
                .forEach(({ data }) => {
                    if (data.order >= order) {
                        order = data.order + 1;
                    }
                });
            setLoading(true);
            createLabels(aiTemplateId, [
                {
                    name: newName,
                    parentAiTemplateLabelId,
                    order,
                    aiTemplateLabelId: ulid(),
                },
            ])
                .then((resp) => {
                    console.log(resp);
                    setLabelList(sortTableRows(resp.data.labels));
                    setAddLabelDialogData(null);
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'ラベルの追加に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [addLabelDialogData, labelList]
    );

    const onDragStart = React.useCallback(
        (item: TableRowData, rootId: string, index: number) => {
            setSnapShot(labelList.map((item) => item.data));
            setDragData({ item, rootId, index });
        },
        [labelList]
    );

    const onDragEnter = React.useCallback(
        (item: TableRowData, rootId: string, index: number) => {
            if (!dragData) {
                return false;
            }
            if (
                dragData.item.data.aiTemplateLabelId ==
                item.data.aiTemplateLabelId
            ) {
                return false;
            }
            if (dragData.item.rootId != rootId) {
                return false;
            }
            if (dragData.index == index) {
                return false;
            }
            const srcItemId = dragData.item.data.aiTemplateLabelId;
            const dstItemId = item.data.aiTemplateLabelId;
            const labels = labelList.map((item) => {
                return { ...item.data };
            });
            const srcItem = labels.find(
                (item) => item.aiTemplateLabelId == srcItemId
            );
            const dstItem = labels.find(
                (item) => item.aiTemplateLabelId == dstItemId
            );

            if (!srcItem || !dstItem) {
                return false;
            }

            const isChild = (
                parentId: string,
                childId: string,
                items: Array<Label>
            ) => {
                let id: string = childId;
                while (id) {
                    if (id == parentId) {
                        return true;
                    }
                    id =
                        items?.find((item) => item.aiTemplateLabelId == id)
                            ?.parentAiTemplateLabelId || '';
                }
                return false;
            };
            if (
                srcItem.parentAiTemplateLabelId ==
                dstItem.parentAiTemplateLabelId
            ) {
                const tmpOrder = srcItem.order;
                srcItem.order = dstItem.order;
                dstItem.order = tmpOrder;
            } else if (
                !isChild(
                    srcItem.aiTemplateLabelId,
                    dstItem.aiTemplateLabelId,
                    labels
                )
            ) {
                const beforeParent = srcItem.parentAiTemplateLabelId;
                srcItem.parentAiTemplateLabelId =
                    dstItem.parentAiTemplateLabelId;
                srcItem.order = dstItem.order - 1;

                // 以前いた要素のオーダーも修正しておく
                labels
                    .filter(
                        (item) => item.parentAiTemplateLabelId == beforeParent
                    )
                    .sort((a, b) => a.order - b.order)
                    .forEach((item, index) => {
                        item.order = index;
                    });
            }
            labels
                .filter(
                    (item) =>
                        item.parentAiTemplateLabelId ==
                        srcItem.parentAiTemplateLabelId
                )
                .sort((a, b) => a.order - b.order)
                .forEach((item, index) => {
                    item.order = index;
                });

            const nextState = sortTableRows(labels);
            setLabelList(nextState);
            const nextIndex = nextState.findIndex(
                (item) =>
                    item.data.aiTemplateLabelId ==
                    dragData.item.data.aiTemplateLabelId
            );

            setDragData({
                item: nextState[nextIndex],
                rootId,
                index: nextIndex,
            });
        },
        [dragData, labelList]
    );

    const onDragEnd = React.useCallback(() => {
        const updated: Array<Label> = [];
        labelList.forEach(({ data }) => {
            const preItem = snapShot?.find(
                (item) => item.aiTemplateLabelId == data.aiTemplateLabelId
            );
            if (!preItem) {
                return;
            }
            if (
                preItem.parentAiTemplateLabelId !=
                    data.parentAiTemplateLabelId ||
                preItem.order != data.order
            ) {
                updated.push(data);
            }
        });
        if (!updated.length) {
            return;
        }
        setLoading(true);
        updateLabels(aiTemplateId, updated)
            .then((resp) => {
                console.log(resp);
            })
            .catch((err) => {
                console.warn(err);
                setErrors((prevState) => [
                    ...prevState,
                    err.message || 'ラベルの順番の変更に失敗しました',
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
        setDragData(null);
    }, [labelList, snapShot]);

    const onDeleteLabel = React.useCallback(
        ({ name, aiTemplateLabelId }) => {
            setDeleteLabelDialogData({ name, aiTemplateLabelId });
        },
        [labelList]
    );

    const onDeleteLabelDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            const { aiTemplateLabelId } = deleteLabelDialogData ?? {};
            if (!aiTemplateLabelId) {
                return;
            }
            setLoading(true);
            deleteLabels(aiTemplateId, [aiTemplateLabelId])
                .then((resp) => {
                    console.log(resp);
                    setLabelList(sortTableRows(resp.data.labels));
                    setDeleteLabelDialogData(null);
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'ラベルの削除に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [labelList, deleteLabelDialogData]
    );

    const onDeleteLabelDialogCancel = React.useCallback(() => {
        setDeleteLabelDialogData(null);
    }, []);

    const onChangeEditLabelDialogText = React.useCallback((value: string) => {
        setEditLabelDialogData((prevState) => {
            return { ...prevState!, name: value };
        });
    }, []);

    const onEdit = React.useCallback((item: Label, name: string) => {
        setEditLabelDialogData({
            aiTemplateLabelId: item.aiTemplateLabelId,
            name: name,
        });
    }, []);

    const onEditLabelDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            const newName =
                editLabelDialogData?.name?.replace(/^[\s　]+|[\s　]+$/g, '') ||
                '';
            const label = labelList?.find(
                (item) =>
                    item.data.aiTemplateLabelId ==
                    editLabelDialogData?.aiTemplateLabelId
            )?.data;
            if (label) {
                label.name = newName;
                setLoading(true);
                updateLabels(aiTemplateId, [label])
                    .then((resp) => {
                        console.log(resp);
                        setLabelList(sortTableRows(resp.data.labels));
                        setEditLabelDialogData(null);
                    })
                    .catch((err) => {
                        console.warn(err);
                        dialog.addError(
                            err.message || 'ラベルの更新に失敗しました'
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [labelList, editLabelDialogData]
    );

    const onEditLabelDialogCancel = React.useCallback(() => {
        setEditLabelDialogData(null);
    }, []);

    const isVisible = (id: string | undefined) => {
        id = labelList.find((item) => item.data.aiTemplateLabelId == id)?.data
            ?.parentAiTemplateLabelId;
        while (id) {
            const item = labelList.find(
                (item) => item.data.aiTemplateLabelId == id
            );
            if (!item) {
                break;
            }
            if (!item.opened) {
                return false;
            }
            id = item.data.parentAiTemplateLabelId;
        }
        return true;
    };

    if (error) {
        return <ErrorDisplay error={error} />;
    }
    if (!templateName || !aiAnalysisTypes) {
        return <Loading />;
    }

    return (
        <>
            <div className="app-main admin-template">
                <AdminDetailPageHeader
                    parentUrl="/app/admin/templates"
                    name={templateName}
                    onDelete={onDeleteTemplate}
                    onEdit={onEditTemplateName}
                    title="テンプレート名"
                    annotation="テンプレートを削除すると、関連するプロジェクトも削除されます。"
                />
                <section>
                    <h3>AI種別</h3>
                    <div className="template-ai-analysis-type-header">
                        <div className="ai-name" role="name">
                            {
                                aiAnalysisTypes.find(
                                    (item) =>
                                        item.aiAnalysisTypeId ==
                                        aiAnalysisTypeId
                                )?.nameJa
                            }
                        </div>
                    </div>
                </section>
                <section>
                    <div className="template-label-header">
                        <h3 role="title">ラベル</h3>
                        <div role="buttons">
                            <IconButton
                                icon="fa-solid fa-folder-plus"
                                onClick={() => {
                                    onAddNewLabel(undefined);
                                }}
                            >
                                ルートラベルの追加
                            </IconButton>
                        </div>
                    </div>
                    <table className="template-label-table">
                        <tbody>
                            {labelList.map((item, index) =>
                                item.data.parentAiTemplateLabelId ? (
                                    // ドラッグ可能な通常の子要素
                                    <tr
                                        key={item.data.aiTemplateLabelId}
                                        draggable={true}
                                        onDragStart={() =>
                                            onDragStart(
                                                item,
                                                item.rootId,
                                                index
                                            )
                                        }
                                        onDragEnd={() => onDragEnd()}
                                        onDragEnter={() =>
                                            onDragEnter(
                                                item,
                                                item.rootId,
                                                index
                                            )
                                        }
                                        style={{
                                            display: isVisible(
                                                item.data.aiTemplateLabelId
                                            )
                                                ? 'table-row'
                                                : 'none',
                                        }}
                                    >
                                        <td
                                            style={{
                                                paddingLeft:
                                                    item.depth * 2 + 'em',
                                            }}
                                        >
                                            <div className="template-label-list-item">
                                                <div role="name">
                                                    {item.hasChildren &&
                                                        item.opened && (
                                                            <Link
                                                                to="#"
                                                                className="label-list-item-open-icon"
                                                                onClick={() => {
                                                                    const aiTemplateLabelId =
                                                                        item
                                                                            .data
                                                                            .aiTemplateLabelId;
                                                                    setLabelList(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            const nextState =
                                                                                JSON.parse(
                                                                                    JSON.stringify(
                                                                                        prevState
                                                                                    )
                                                                                );
                                                                            const targetItem =
                                                                                nextState.find(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item
                                                                                            .data
                                                                                            .aiTemplateLabelId ==
                                                                                        aiTemplateLabelId
                                                                                );
                                                                            if (
                                                                                targetItem
                                                                            ) {
                                                                                targetItem.opened =
                                                                                    false;
                                                                            }
                                                                            return nextState;
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                <FolderOpenIcon />
                                                            </Link>
                                                        )}
                                                    {item.hasChildren &&
                                                        !item.opened && (
                                                            <Link
                                                                to="#"
                                                                className="label-list-item-closed-icon"
                                                                onClick={() => {
                                                                    const aiTemplateLabelId =
                                                                        item
                                                                            .data
                                                                            .aiTemplateLabelId;
                                                                    setLabelList(
                                                                        (
                                                                            prevState
                                                                        ) => {
                                                                            const nextState =
                                                                                JSON.parse(
                                                                                    JSON.stringify(
                                                                                        prevState
                                                                                    )
                                                                                );
                                                                            const targetItem =
                                                                                nextState.find(
                                                                                    (
                                                                                        item
                                                                                    ) =>
                                                                                        item
                                                                                            .data
                                                                                            .aiTemplateLabelId ==
                                                                                        aiTemplateLabelId
                                                                                );
                                                                            if (
                                                                                targetItem
                                                                            ) {
                                                                                targetItem.opened =
                                                                                    true;
                                                                            }
                                                                            return nextState;
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                <FolderClosedIcon />
                                                            </Link>
                                                        )}
                                                    {item.data.name}
                                                </div>
                                                <div role="buttons">
                                                    <IconButton
                                                        icon="fa-solid fa-folder-plus"
                                                        onClick={() => {
                                                            onAddNewLabel(
                                                                item.data
                                                                    .aiTemplateLabelId
                                                            );
                                                        }}
                                                    >
                                                        新しいラベルの追加
                                                    </IconButton>
                                                    <IconButton
                                                        icon="fa-solid fa-pencil"
                                                        onClick={() => {
                                                            onEdit(
                                                                item.data,
                                                                item.data.name
                                                            );
                                                        }}
                                                    >
                                                        編集
                                                    </IconButton>
                                                    <IconButton
                                                        icon="fa-solid fa-trash"
                                                        onClick={() => {
                                                            onDeleteLabel(
                                                                item.data
                                                            );
                                                        }}
                                                    >
                                                        削除
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    // ドラッグ不可能な親要素
                                    <tr key={item.data.aiTemplateLabelId}>
                                        <td>
                                            <div className="root-label">
                                                <h4 role="name">
                                                    {item.data.name}
                                                </h4>
                                                <div role="buttons">
                                                    <IconButton
                                                        icon="fa-solid fa-folder-plus"
                                                        onClick={() =>
                                                            onAddNewLabel(
                                                                item.data
                                                                    .aiTemplateLabelId
                                                            )
                                                        }
                                                    >
                                                        新しいラベルの追加
                                                    </IconButton>
                                                    <IconButton
                                                        icon="fa-solid fa-pencil"
                                                        onClick={() => {
                                                            onEdit(
                                                                item.data,
                                                                item.data.name
                                                            );
                                                        }}
                                                    >
                                                        ラベル名の編集
                                                    </IconButton>
                                                    <IconButton
                                                        icon="fa-solid fa-trash"
                                                        onClick={() =>
                                                            onDeleteLabel(
                                                                item.data
                                                            )
                                                        }
                                                    >
                                                        ラベルの削除
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </section>
            </div>
            {editLabelDialogData != null && (
                <DialogBox
                    title={'ラベルの編集'}
                    okText={'更新'}
                    onCancel={onEditLabelDialogCancel}
                    onOK={onEditLabelDialogOK}
                >
                    <ul className="fields">
                        <li>
                            <TextField
                                label="ラベル名"
                                _onChange={onChangeEditLabelDialogText}
                                value={editLabelDialogData.name}
                                required={true}
                            />
                        </li>
                    </ul>
                </DialogBox>
            )}
            {addLabelDialogData != null && (
                <DialogBox
                    title={'ラベルの追加'}
                    okText={'追加'}
                    onCancel={onAddLabelDialogCancel}
                    onOK={onAddLabelDialogOK}
                >
                    <ul className="fields">
                        <li>
                            <TextField
                                label="ラベル名"
                                _onChange={onChangeAddLabelDialogText}
                                value={addLabelDialogData.name}
                                required={true}
                            />
                        </li>
                    </ul>
                </DialogBox>
            )}
            {deleteLabelDialogData && (
                <DialogBox
                    title="確認"
                    onOK={onDeleteLabelDialogOK}
                    onCancel={onDeleteLabelDialogCancel}
                >
                    {deleteLabelDialogData.name} を削除しますか？
                </DialogBox>
            )}
            {loading && <Loading />}
        </>
    );
};
