import { LabelID } from '../utils/ServerClientModel';

export interface AiTemplateModel {
    aiTemplateId: string;
    aiAnalysisTypeId: string;
    ai: number;
    name: string;
    labels: Label[];
    runnable: boolean;
}

export interface Label {
    aiTemplateLabelId: LabelID;
    name: string;
    order: number;
    parentAiTemplateLabelId?: LabelID;
}

export interface LabelTreeItem extends Omit<Label, 'parent'> {
    parent: LabelTreeItem | null;
    children: LabelTreeItem[];
    fullName: string;
}

export interface CompiledLabelChildOption {
    level: LabelTreeItem;
    selectedOption: string;
    selected: LabelTreeItem;
    options: {
        label: LabelTreeItem;
        selected: boolean;
    }[];
}

export interface CompiledLabelOption {
    root: LabelTreeItem;
    children: CompiledLabelChildOption[];
}

export function getLabelTreeChildren(
    lookup: { [labelId: string]: LabelTreeItem },
    labels: Label[],
    label: LabelTreeItem | null
): LabelTreeItem[] {
    return labels
        .filter((child) =>
            label === null
                ? !child.parentAiTemplateLabelId
                : child.parentAiTemplateLabelId == label.aiTemplateLabelId
        )
        .map((child) => {
            let fullName: string | undefined;
            const item: LabelTreeItem = {
                ...child,
                parent: label,
                children: [],
                get fullName() {
                    if (fullName === undefined) {
                        fullName = renderLabelFullName(item);
                    }
                    return fullName;
                },
            };
            item.children = getLabelTreeChildren(lookup, labels, item);
            lookup[item.aiTemplateLabelId] = item;
            return item;
        });
}

export function renderLabelFullName(item: LabelTreeItem): string {
    if (!item.parent) {
        return item.name;
    }
    return `${item.parent.fullName} » ${item.name}`;
}

export type LabelTreeLookup = { [id: string]: LabelTreeItem };

export interface LabelTree {
    roots: LabelTreeItem[];
    all: LabelTreeItem[];
    lookup: LabelTreeLookup;
}

export function createLabelTree(labels: Label[]): LabelTree {
    const lookup: { [id: string]: LabelTreeItem } = {};
    const roots = labels ? getLabelTreeChildren(lookup, labels, null) : [];
    return {
        roots,
        all: Object.values(lookup),
        lookup,
    };
}

export function getRouteByRoots(
    lookup: LabelTreeLookup,
    labelIds?: LabelID[]
): { [root: LabelID]: LabelTreeItem[] } {
    if (!labelIds) return {};
    const selectedByRoots: { [labelId: string]: LabelTreeItem[] } = {};
    for (const labelId of labelIds) {
        let selected = lookup[labelId];
        if (!selected) {
            continue;
        }
        const route = [selected];
        while (selected.parent) {
            selected = selected.parent;
            route.unshift(selected);
        }
        if (selectedByRoots[selected.aiTemplateLabelId]) {
            // First one for route wins!
            continue;
        }
        selectedByRoots[selected.aiTemplateLabelId] = route;
    }
    return selectedByRoots;
}

export interface ExportTemplateLabelModel {
    label: string;
    children?: ExportTemplateLabelModel[];
}

export interface ExportTemplateModel {
    aiAnalysisTypeId: string;
    name: string;
    labels: ExportTemplateLabelModel[];
}
export interface ExportTenantTagsModel {
    name: string;
    tags: ExportTenantTagModel[];
}
export interface ExportTenantTagModel {
    name: string;
    type: number;
    list?: null | string[];
}
