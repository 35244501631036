import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useFetchUserSelf } from '../../../hooks/useUser';
import { useAuth } from '../../../contexts/AuthContext';
import { DialogBox } from '../../atomic/dialog';
import { Loading } from '../../atomic/effects';
import {
    getTenantForSuperAdmin,
    getTenantOptions,
    putTenantOptions,
} from '../../../APIs/tenants';
import { projectApi } from '../../../APIs/projectApi';
import { useGet, useMutation } from '../../../hooks/useApi';
import {
    AiAnalysisType,
    AiTypeUpdateRequest,
} from '../../../models/AiAnalysisType';
import { SlideSwitch } from '../../atomic/switches';
import { TenantOptionsModel } from '../../../models/TenantModel';

type Prop = {
    tenantId: string;
    isOpen: any;
    setIsOpen: any;
};

type Tenant =
    | {
          name: string;
      }
    | undefined;

export const TenantEdit: FC<Prop> = (props) => {
    const { isOpen, setIsOpen, tenantId } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [updating, setUpdating] = useState<boolean>(false);
    const [tenant, setTenant] = useState<Tenant>(undefined);
    const [tenantOptions, setTenantOptions] =
        useState<TenantOptionsModel | null>(null);
    const [mosaicOptions, setMosaicOptions] = useState<boolean | null>(null);
    const [extApiOptions, setExtApiOptions] = useState<boolean | null>(null);
    const [updateAiTypes, setUpdateAiTypes] = useState<string[]>([]);
    const ref = useRef<DialogBox>();

    const openModal = () => {
        setIsOpen(true);
    };

    const {
        isSending: isUpdateing,
        send: updateAi,
        data: updateAiResult,
    } = useMutation(projectApi.mutate.updateAiType(tenantId));

    const allTypes = useGet(projectApi.get.allAiAnalysisTypes);

    const tenantAiTypes = useGet(
        projectApi.get.tenantAiAnalysisTypes(tenantId)
    );

    useEffect(() => {
        tenantAiTypes.mutate();
    }, [isOpen]);

    const onOK = useCallback(async () => {
        if (
            !confirm(
                'AIを変更するとプロジェクトやテンプレートが削除されますがよろしいですか？'
            )
        ) {
            return;
        }
        setUpdating(true);
        const updateAiTypesData: AiTypeUpdateRequest = {
            aiAnalysisTypeIds: updateAiTypes,
        };
        try {
            await updateAi(updateAiTypesData);
            await putTenantOptions(tenantId, tenantOptions);
            await putTenantOptions(tenantId, {
                mosaic: {
                    enable: mosaicOptions ? true : false,
                },
            });
            if (extApiOptions) {
                await putTenantOptions(tenantId, {
                    extApi: {
                        enable: true,
                    },
                });
            } else {
                await putTenantOptions(tenantId, {
                    extApi: {},
                });
            }
        } catch (err) {
            console.warn(err);
        } finally {
            setUpdating(false);
        }
        updateData(tenantId);
    }, [updateAiTypes, tenantOptions, mosaicOptions, extApiOptions]);

    const onCancel = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (!updateAiResult) {
            return;
        }
        setIsOpen(false);
    }, [isUpdateing]);

    const updateData = (tenantId) => {
        if (tenantId) {
            setLoading(true);
            Promise.all([
                getTenantForSuperAdmin(tenantId),
                getTenantOptions({
                    tenantId: tenantId,
                    category: 'kintone',
                    keys: ['id', 'pass', 'endpoint'],
                }),
                getTenantOptions({
                    tenantId: tenantId,
                    category: 'mosaic',
                    keys: ['enable'],
                }),
                getTenantOptions({
                    tenantId: tenantId,
                    category: 'extApi',
                    keys: ['enable'],
                }),
            ])
                .then((resps) => {
                    if (resps[0]?.data) {
                        setTenant(resps[0].data);
                    } else {
                        console.warn(resps[0]);
                        ref?.current?.addError(
                            resps[0],
                            'エラーが発生しました'
                        );
                    }
                    resps[2].data.mosaic?.enable
                        ? setMosaicOptions(true)
                        : setMosaicOptions(false);
                    resps[3].data.extApi?.enable
                        ? setExtApiOptions(true)
                        : setExtApiOptions(false);

                    setTenantOptions(resps[1].data);
                })
                .catch((err) => {
                    console.warn(err);
                    ref?.current?.addError(err, 'エラーが発生しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setTenant(undefined);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        updateData(tenantId);
    }, [tenantId]);

    const OptionsList = useMemo(
        () => (
            <ul className="fields switch-list">
                <li>
                    <SlideSwitch
                        text={'kintone連携'}
                        defaultValue={
                            tenantOptions != null && 'kintone' in tenantOptions
                        }
                        name={'option.kintone'}
                        textPosition="left"
                        _onChange={(checked: boolean) => {
                            if (tenantOptions && 'kintone' in tenantOptions) {
                                if (!checked) {
                                    setTenantOptions((prevState) => {
                                        const nextState = prevState
                                            ? { ...prevState }
                                            : {};
                                        nextState['kintone'] = {};
                                        return nextState;
                                    });
                                } else {
                                    setTenantOptions((prevState) => {
                                        const nextState = prevState
                                            ? { ...prevState }
                                            : {};
                                        nextState['kintone'] = {
                                            id: '',
                                            pass: '',
                                            endpoint: '',
                                        };
                                        return nextState;
                                    });
                                }
                            } else {
                                if (checked) {
                                    setTenantOptions((prevState) => {
                                        const nextState = prevState
                                            ? { ...prevState }
                                            : {};
                                        nextState['kintone'] = {
                                            id: '',
                                            pass: '',
                                            endpoint: '',
                                        };
                                        return nextState;
                                    });
                                }
                            }
                        }}
                    />
                </li>
                <li>
                    <SlideSwitch
                        text={'モザイク設定'}
                        defaultValue={mosaicOptions ? true : false}
                        name={'option.kintone'}
                        textPosition="left"
                        _onChange={(checked: boolean) => {
                            setMosaicOptions(checked);
                        }}
                    />
                </li>
                <li>
                    <SlideSwitch
                        text={'外部API設定'}
                        defaultValue={extApiOptions ? true : false}
                        name={'option.kintone'}
                        textPosition="left"
                        _onChange={(checked: boolean) => {
                            setExtApiOptions(checked);
                        }}
                    />
                </li>
            </ul>
        ),
        [tenantOptions, mosaicOptions]
    );

    if (!tenantAiTypes.data || !allTypes.data || loading) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <>
            <div>
                {isOpen && (
                    <DialogBox
                        title="テナント編集"
                        onOK={onOK}
                        onCancel={onCancel}
                        ref={ref}
                    >
                        <h2>{tenant?.name}</h2>
                        <hr />
                        <section>
                            <h3>利用可能なAI</h3>
                            <ul className="fields switch-list">
                                <AiTypeList
                                    tenantAiTypes={tenantAiTypes.data.data}
                                    allTypes={allTypes.data.data}
                                    tenantId={tenantId}
                                    setUpdateAiTypes={setUpdateAiTypes}
                                />
                            </ul>
                        </section>
                        <section>
                            <h3>オプション</h3>
                            {OptionsList}
                        </section>
                    </DialogBox>
                )}
                {updating && <Loading />}
            </div>
        </>
    );
};

type AiTypesProps = {
    allTypes: AiAnalysisType[];
    tenantId: string;
    tenantAiTypes: AiAnalysisType[];
    setUpdateAiTypes;
};

const AiTypeList: FC<AiTypesProps> = (props) => {
    const { allTypes, tenantId, tenantAiTypes, setUpdateAiTypes } = props;
    const [aiList, setAiList] = useState<string[]>([]);

    const defaultAiList = useMemo(() => {
        const types = tenantAiTypes.map((item) => {
            return item.aiAnalysisTypeId;
        });
        setAiList(types);
    }, [tenantAiTypes, allTypes]);

    function check(aiAnalysisTypeId) {
        return aiList.some((item) => {
            return aiAnalysisTypeId === item;
        });
    }

    const onChange = (name: string) => {
        setAiList((prev) => {
            const count = prev.filter((item) => {
                return item === name;
            }).length;
            if (count) {
                const newList = prev.filter((item) => {
                    return item !== name;
                });
                return newList;
            }
            const newList = [...prev, name];
            return newList;
        });
    };
    useEffect(() => {
        setUpdateAiTypes(aiList);
    }, [aiList]);

    return (
        <>
            {allTypes.map((type: AiAnalysisType) => {
                return (
                    <AiType
                        key={type.aiAnalysisTypeId}
                        type={type}
                        tenantId={tenantId}
                        onChange={onChange}
                        checked={check(type.aiAnalysisTypeId)}
                    ></AiType>
                );
            })}
        </>
    );
};
type AiProps = {
    type: AiAnalysisType;
    tenantId: string | undefined;
    checked: boolean;
    onChange;
};

const AiType: FC<AiProps> = (props) => {
    const { type, tenantId, checked, onChange } = props;

    const aiAnalysisTypeId = type.aiAnalysisTypeId;
    const value = checked;

    if (type.runnable === false && !checked) {
        return <></>;
    }

    return (
        <li className={type.runnable ? '' : 'disabled'}>
            <SlideSwitch
                disabled={!type.runnable}
                text={type.nameJa + (type.runnable ? '' : '(廃止)')}
                defaultValue={checked}
                name={type.aiAnalysisTypeId}
                textPosition="left"
                onChange={() => {
                    onChange(aiAnalysisTypeId);
                }}
            />
        </li>
    );
};
