import React, { ChangeEvent, FocusEvent, MouseEvent, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ReelSession } from './model/ReelSession';
import { SelectField } from '../atomic/forms';
import { IconButton } from '../atomic/buttons';
import { Icons } from '../atomic/icons';
import classNames from 'classnames';
import { useFileType } from '../../hooks/useFileType';
import { SlideSwitch } from '../atomic/switches';
import ReelLeaningPictureButton from './ReelLeaningPictureButton';
import { getAiAnalysisTypes } from '../../APIs/templateApi';
import { useProjectAiType } from '../../APIs/projectApi';

const selectForLi = (e: FocusEvent | MouseEvent) => {
    return (e.currentTarget as HTMLElement).querySelector('select');
};

const setTagFocussed = (e: FocusEvent | MouseEvent) => {
    selectForLi(e)?.focus();
};

export interface ReelTagsProps {
    projectId: string;
    fileId: string;
    session: ReelSession;
}

export interface ReelTagListProps {
    session: ReelSession;
}

const ReelTagList = observer(({ session }: ReelTagListProps) => {
    const handler = useMemo(
        () => ({
            onTagOver(e: MouseEvent) {
                session.setHoverTag(selectForLi(e)?.name);
            },
            onTagOut(e: MouseEvent) {
                session.setHoverTag(undefined);
            },
            onTagFocussed(e: FocusEvent<HTMLSelectElement>) {
                session.setFocussedTag(e.target.name);
            },
            onTagDown(e: MouseEvent<HTMLSelectElement>) {
                const select = e.currentTarget;
                if (!select.matches(':focus')) {
                    e.preventDefault();
                    select.focus();
                }
            },
            onTagBlurred(e: FocusEvent) {
                session.setFocussedTag(undefined);
            },
            addTag: () => session.addRandomTag(),
            changeTag(e: ChangeEvent) {
                const target = e.target as HTMLSelectElement;
                session.changeTagType(target.name, Number(target.value));
            },
        }),
        [session]
    );

    return (
        <ul className="reel--tag-items">
            {session.compiledTags.map((tag) => (
                <li
                    key={tag.id}
                    id={tag.id}
                    onClick={setTagFocussed}
                    onMouseOver={handler.onTagOver}
                    onMouseOut={handler.onTagOut}
                    className={classNames('reel--tag-item', {
                        'reel--tag-item--hovered': session.hoveredTag === tag,
                        'reel--tag-item--selected': session.focussedTag === tag,
                    })}
                >
                    <SelectField
                        value={String(tag.typeId)}
                        name={tag.id}
                        onChange={handler.changeTag}
                        onMouseDown={handler.onTagDown}
                        onMouseOver={handler.onTagOver}
                        onMouseOut={handler.onTagOut}
                        onFocus={handler.onTagFocussed}
                        onBlur={handler.onTagBlurred}
                        options={session.aiLabels.map((tagType) => ({
                            value: String(tagType.id),
                            text: tagType.nameJa,
                        }))}
                    />
                    <IconButton
                        icon={Icons.Delete}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            session.deleteTag(tag.id);
                        }}
                    />
                </li>
            ))}
            <li>
                <IconButton icon={Icons.Add} onClick={handler.addTag} />
            </li>
        </ul>
    );
});
export const ReelTags = observer(
    ({ fileId, session, projectId }: ReelTagsProps) => {
        const { isPhoto } = useFileType(fileId);
        const aiAnalysisType = useProjectAiType(projectId);

        if (!isPhoto) return <></>;

        return (
            <>
                <div className="reel--show-tags">
                    <SlideSwitch
                        text="結果を表示"
                        defaultValue={session.showTags}
                        _onChange={session.toggleShowTags}
                    />

                    {!!aiAnalysisType.data && (
                        <ReelLeaningPictureButton
                            aiAnalysisTypeId={
                                aiAnalysisType.data.aiAnalysisTypeId
                            }
                            fileId={fileId}
                        />
                    )}
                </div>
                {session.showTags && !session.readonly && (
                    <ReelTagList session={session} />
                )}
            </>
        );
    }
);
