import React, { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useGet } from '../hooks/useApi';
import { projectApi } from '../APIs/projectApi';
import { DialogBox } from '../components/atomic/dialog';
import {
    SelectField,
    SelectFieldOption,
    SelectFieldOptionGroup,
    TextField,
} from '../components/atomic/forms';
import { CreateProjectRequest } from '../models/ProjectModel';
import { DialogProps } from '../hooks/useDialog';
import { templateApi } from '../APIs/templateApi';
import { useLocalConfigVideoFields } from './config/useLocalConfigVideoFields';
import { useUserConfig } from '../hooks/useUserConfig';

export interface CreateProjectDialogProps
    extends DialogProps<{ folderId: string }, CreateProjectRequest> {}
export const CreateProjectDialog = (opts: CreateProjectDialogProps) => {
    const { data: aiAnalysisTypes } = useGet(projectApi.get.aiAnalysisTypes);
    const { data: aiTemplates } = useGet(templateApi.get.aiTemplates);
    const [target, setTarget] = useState<string>();
    const [name, setName] = useState('');
    const { options, onOK: onCommit, ...rest } = opts;

    const lookup = useMemo(() => {
        const templateOptions: SelectFieldOption[] = [];
        const aiOptions: SelectFieldOption[] = [];
        const dataForValue: {
            [target: string]: {
                defaultName: string;
                opts: Pick<
                    CreateProjectRequest,
                    'aiAnalysisTypeId' | 'aiTemplateId'
                >;
            };
        } = {};
        if (aiTemplates?.data && aiAnalysisTypes?.data) {
            for (const template of aiTemplates.data) {
                if (!template.runnable) {
                    continue;
                }
                const value = `t:${template.aiTemplateId}`;
                dataForValue[value] = {
                    defaultName: template.name,
                    opts: {
                        aiTemplateId: template.aiTemplateId,
                    },
                };
                templateOptions.push({
                    value,
                    text: template.name,
                });
            }
            for (const aiAnalysisType of aiAnalysisTypes.data) {
                if (!aiAnalysisType.runnable) {
                    continue;
                }
                const value = `a:${aiAnalysisType.aiAnalysisTypeId}`;
                dataForValue[value] = {
                    defaultName: aiAnalysisType.nameJa,
                    opts: {
                        aiAnalysisTypeId: aiAnalysisType.aiAnalysisTypeId,
                    },
                };
                aiOptions.push({
                    value,
                    text: aiAnalysisType.nameJa,
                });
            }
        }
        const options: SelectFieldOptionGroup[] = [
            {
                label: 'テンプレートから選択',
                options: templateOptions,
            },
            {
                label: 'AIから選択',
                options: aiOptions,
            },
        ];
        return {
            options,
            dataForValue,
        };
    }, [aiTemplates?.data, aiAnalysisTypes?.data]);

    const currentOption = useMemo(() => {
        if (!target) return;
        return lookup.dataForValue[target];
    }, [target, lookup]);

    const defaultName = useMemo(
        () => currentOption?.defaultName ?? '',
        [currentOption]
    );
    const config = useUserConfig();
    const [video, setVideo] = useState(config.get().video);
    const optionFields = useLocalConfigVideoFields({
        video,
        setVideo,
        disable: false,
        showSliceSwitch: true,
    });

    useEffect(() => {
        if (target) return;
        for (const firstTarget in lookup.dataForValue) {
            setTarget(firstTarget);
            return;
        }
    }, [target, lookup]);

    const onOk = useCallback(() => {
        if (!currentOption) return;
        onCommit({
            folderId: options.folderId,
            name: name || defaultName,
            ...currentOption.opts,
        });
    }, [currentOption, name, defaultName, video]);
    return (
        <>
            <DialogBox
                title="プロジェクトの作成"
                onOK={onOk}
                okText={'作成'}
                {...rest}
                disabled={!currentOption}
            >
                <ul className="fields">
                    <li>
                        <TextField
                            label="プロジェクト名"
                            value={name}
                            _onChange={setName}
                            placeholder={defaultName}
                        />
                    </li>
                    <li>
                        <SelectField
                            label="設定"
                            value={target}
                            _onChange={setTarget}
                            options={lookup.options}
                        />
                    </li>
                </ul>
            </DialogBox>
        </>
    );
};
