import React from 'react';
import { useProjectAiType } from '../../APIs/projectApi';
import { Button, IconButton } from '../atomic/buttons';

import { FilesAPI } from '../../APIs/filesApi';
import { useGet } from '../../hooks/useApi';

export interface ReelLeaningPictureButtonProps {
    fileId: string;
    aiAnalysisTypeId: string;
}

const ReelLeaningPictureButton = (props: ReelLeaningPictureButtonProps) => {
    const { fileId, aiAnalysisTypeId } = props;
    const [disabled, setDisabled] = React.useState<boolean>(false);

    const onClick = React.useCallback(() => {
        setDisabled(true);
        FilesAPI.lean.post(aiAnalysisTypeId, fileId);
    }, [fileId, aiAnalysisTypeId]);
    const lean = useGet(FilesAPI.lean.get(aiAnalysisTypeId, fileId));
    React.useEffect(() => {
        setDisabled(true);
    }, [fileId]);
    React.useEffect(() => {
        if (lean.data == undefined || lean.data.data.aiAnalysisTypeId) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [lean.data]);

    if (!aiAnalysisTypeId) {
        return <></>;
    }

    return (
        <IconButton
            icon={'fa-solid fa-share-nodes'}
            disabled={disabled}
            title={disabled ? '画像申告済' : '画像申告'}
            onClick={() => {
                onClick();
            }}
        />
    );
};

export default ReelLeaningPictureButton;
